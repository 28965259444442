import { Part } from "./part";

export class InvoiceLine {
    id!: number;
    lineNumber!: number;
    partId!: number;
    partNumber?: string;
    quantity!: number;
    price!: number;
    description?: string;
    part!: Part;
    isCoreCharge!: boolean;

    newQuantity!: number;

    get title() {
        if (this.isCoreCharge) return 'Core charge';
        return this.part.title;
    }

    public constructor(init?:Partial<InvoiceLine>) {
        Object.assign(this, {
            ...init,
            part: new Part(init?.part),
            newQuantity: init?.quantity
        });
    }
}
