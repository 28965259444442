import { Injectable, computed, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApplicationInfoService } from './application-info.service';

type resizeMode = 'crop' | 'stretch' | 'max';

@Injectable({
    providedIn: 'root'
})
export class PictureService {
    appInfo = inject(ApplicationInfoService);

    container: string;
    pictureBaseUrl: string;
    partUrl: string;
    partTypeUrl: string;
    webPhotoUrl: string;
    noImageUrl = computed(() => `${environment.isWebComponent ? this.appInfo.shopUrl() : ''}/assets/images/no-image.jpg`);

    constructor() {
        this.container = this.appInfo.info.containerName!;
        this.pictureBaseUrl = `${this.appInfo.shopUrl()}/${this.container}/media/images`;
        this.partUrl = `${this.pictureBaseUrl}/partphoto`;
        this.partTypeUrl = `${this.pictureBaseUrl}/part-types`;
        this.webPhotoUrl = `${this.pictureBaseUrl}/webphoto`;
        // this.noImageUrl = `${environment.isWebComponent ? this.appInfo.shopUrl() : ''}/assets/images/no-image.jpg`;
    }

    getImageUrl(type: 'partType' | 'part' | 'webPhoto', fileName?: string, width?: number, height?: number, mode?: resizeMode) {
        if (!fileName) return this.noImageUrl();

        let baseUrl = '';
        if (type === 'part') baseUrl = this.partUrl;
        if (type === 'partType') baseUrl = this.partTypeUrl;
        if (type === 'webPhoto') baseUrl = this.webPhotoUrl;
        const url = `${baseUrl}/${fileName}`;
        return this.resize(url, width, height, mode);
    }

    resize(url: string, width?: number, height?: number, mode?: resizeMode) {
        let params = [];
        if (width) params.push(`width=${width}`);
        if (height) params.push(`height=${height}`);
        if (mode) params.push(`rmode=${mode}`);
        if (params.length > 0) url = `${url}?${params.join('&')}`;
        return url;
    }
}
