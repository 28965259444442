export class CustomerAddress {
    id!: number;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;

    get displayStreet() {
        return [this.address1, this.address2].filter(x => x).join(' ');
    }

    get displayCityStateZip() {
        let cityState = [this.city, this.state].filter(x => x).join(' ');
        if (cityState && this.zipCode) cityState = `${cityState},`;
        return [cityState, this.zipCode].filter(x => x).join(' ');
    }

    public constructor(init?:Partial<CustomerAddress>) {
        Object.assign(this, init);
    }
}
