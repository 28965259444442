import { UtilityService } from '../utility.service';

export class PartType {
    id!: number;
    name!: string;
    imageFileName?: string;

    public constructor(init?:Partial<PartType>) {
        Object.assign(this, init);
    }

    get path() {
        return `/products/part-type/${this.id}/${UtilityService.toKebabCase(this.name)}`;
    }
}
