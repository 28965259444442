import { UtilityService } from "../utility.service";

export class Model {
    id!: number;
    name!: string;

    urlName!: string;
    make?: string;

    public constructor(init?: Partial<Model>) {
        Object.assign(this, init);
        this.urlName = UtilityService.toKebabCase(this.name);
    }

    get path() {
        return `/products/make/${this.make}/${this.urlName}/list`;
    }
}
