import { Customer } from './customer';
import { InvoiceLine } from './invoice-line';
import { CustomerAddress } from './customer-address';
import { UtilityService } from '../utility.service';

export class Invoice {
    static StatusOptions = {
        Quote: 0,
        InProcess: 1,
        Complete: 2,
        Expired: 3,
        WebInProcess: 4,
        WebComplete: 5
    };

    id!: number;
    invoiceNumber!: number;
    date!: Date;
    tax!: number;
    freight!: number;
    total!: number;
    pONumber?: string;
    typeId!: number;
    statusId!: number;
    shipAddressId!: number;
    customerId!: number;
    shipAddress!: CustomerAddress;
    customer!: Customer;
    invoiceLines!: InvoiceLine[];
    shipMethod?: string;
    source?: string;

    get subtotal() {
        return this.invoiceLines.reduce((total, line) => total + UtilityService.round(line.price * line.quantity, 2), 0);
    }

    get isShipMethodFreight() {
        return !!this.shipMethod && this.shipMethod.startsWith('Freight');
    }

    get freightCarrier() {
        if (!this.shipMethod || !this.shipMethod!.startsWith('Freight:')) return '';
        else return this.shipMethod.slice(8);
    }

    public constructor(init?: Partial<Invoice>) {
        Object.assign(this, {
            ...init,
            tax: init?.tax ?? 0,
            freight: init?.freight ?? 0,
            total: init?.total ?? 0,
            shipMethod: init?.shipMethod ?? null,
            shipAddress: new CustomerAddress(init?.shipAddress),
            customer: new Customer(init?.customer),
            invoiceLines: init?.invoiceLines?.map((invoiceLine) => new InvoiceLine(invoiceLine)) ?? []
        });
    }
}
