import { Injectable, inject } from '@angular/core';
import { StoreService } from './store.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Make } from './models/make';
import { Part } from './models/part';
import { environment } from '../../environments/environment';
import { PartTypeModelMap } from './models/part-type-model-map';
import { PartType } from './models/part-type';
import { ApplicationInfoService } from './application-info.service';

@Injectable({
    providedIn: 'root',
})
export class PartsService {
    appInfo = inject(ApplicationInfoService);
    storeService = inject(StoreService);
    http = inject(HttpClient);

    async getPartTypes() {
        const data = await firstValueFrom(this.http.get<PartType[]>(`${this.appInfo.shopUrl()}/api/parts/part-types`));
        const partTypes = data.map((partType) => new PartType(partType));
        this.storeService.setPartTypes(partTypes);
    }

    async getMakes() {
        const data = await firstValueFrom(this.http.get<Make[]>(`${this.appInfo.shopUrl()}/api/parts/makes`));
        const makes = data.map((make) => new Make(make));
        this.storeService.setMakes(makes);
    }

    async getPartTypeModelMaps() {
        const maps = await firstValueFrom(this.http.get<PartTypeModelMap[]>(`${this.appInfo.shopUrl()}/api/parts/part-type-model-maps`));
        this.storeService.setPartTypeModelMaps(maps);
    }

    async getParts(request: GetPartsRequest) {
        const parts = await firstValueFrom(this.http.post<Part[]>(`${this.appInfo.shopUrl()}/api/parts/list`, {
            search: request.search,
            partTypeId: request.partTypeId,
            make: request.make,
            model: request.model,
            partNumber: request.partNumber,
            hasPictures: request.hasPictures,
            hasPrice: request.hasPrice,
            isFeatured: request.isFeatured,
            take: request.take,
            excludePartId: request.excludePartId,
            partIds: request.partIds
         }));
        return parts.map((part) => {
            return new Part(part);
        });
    }

    async getPart(id: number) {
        const part = await firstValueFrom(this.http.get<Part>(`${this.appInfo.shopUrl()}/api/parts/detail?id=${id}`));
        return part ? new Part(part) : null;
    }

    async getRelatedParts(id: number) {
        const parts = await firstValueFrom(this.http.get<Part[]>(`${this.appInfo.shopUrl()}/api/parts/related-parts?id=${id}`));
        return parts.map((part) => new Part(part));
    }
}
export class GetPartsRequest {
    search?: string;
    partTypeId?: number;
    make?: string;
    model?: string;
    partNumber?: string;
    hasPictures?: boolean;
    hasPrice?: boolean;
    isFeatured?: boolean;
    take?: number;
    excludePartId?: number;
    partIds?: number[];
}
