import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    gtag(eventName: string, ...eventData: any[]) {
        (<any>window).gtag('event', eventName, ...eventData);
    }
}
