import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-alert-dialog',
    standalone: true,
    imports: [CommonModule, RouterLink],
    template: `
        <div class="dialog ajaxCart">
            <div class="dialogHeader k-window-titlebar k-header">
                <div class="k-window-actions">
                    <a role="button" (click)="dialogRef.close()"> </a>
                </div>
            </div>
            <div class="dialogBody">
                <div class="dialogTitle">{{ data.title }}</div>
                <div class="dialogMessage">
                    @if (data.isHtml) {
                        <p [innerHTML]="data.message"></p>
                    } @else {
                        <p>{{ data.message }}</p>
                    }
                </div>
                <div class="dialogButtonToolbar">
                    <button type="button" class="dialogButton" (click)="dialogRef.close()">Close</button>
                </div>
            </div>
        </div>
    `,
    styles: ``,
})
export class AlertDialogComponent {
    public dialogRef: DialogRef = inject(DialogRef);
    public data = inject(DIALOG_DATA);
}
