import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ApplicationInfoService } from '../core/application-info.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-added-to-cart-dialog',
    standalone: true,
    imports: [CommonModule, RouterLink],
    template: `
        <div class="dialog ajaxCart">
            <div class="dialogHeader k-window-titlebar k-header">
                <div class="k-window-actions">
                    <a role="button" (click)="dialogRef.close()"> </a>
                </div>
            </div>
            <div class="dialogBody">
                <div class="dialogTitle">Added to Cart</div>
                <div class="dialogMessage">
                    <p>This part was added to your cart</p>
                </div>
                <div class="dialogButtonToolbar">
                    <button type="button" class="dialogButton" (click)="dialogRef.close()">Continue Shopping</button>
                    <button type="button" class="dialogButton" (click)="checkout()">Checkout Now</button>
                </div>
            </div>
        </div>
    `,
    styles: ``
})
export class AddedToCartDialogComponent {
    public dialogRef: DialogRef = inject(DialogRef);
    appInfo = inject(ApplicationInfoService);
    router = inject(Router);

    checkout() {
        this.dialogRef.close();

        if (environment.isWebComponent) {
            location.href = this.appInfo.addUtm(`${this.appInfo.shopUrl()}/checkout/cart`);
        } else this.router.navigate(['/checkout/cart']);
    }
}
