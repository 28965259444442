import { Dialog } from '@angular/cdk/dialog';
import { Injectable, Type, inject } from '@angular/core';
import { AlertDialogComponent } from '../shared/alert-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    dialog = inject(Dialog);

    alert(message: string, isHtml: boolean = false) {
        const dialogRef = this.dialog.open<string>(AlertDialogComponent, {
            // width: '250px',
            data: {
                title: 'Alert',
                message,
                isHtml
            },
        });

        return firstValueFrom(dialogRef.closed);
    }

    show<T>(component: Type<T>, data?: any) {
        const dialogRef = this.dialog.open<any>(component, {
            width: 'auto',
            height: 'auto',
            //maxHeight: '100%',
            //maxWidth: '100%',
            data: data
        });

        return firstValueFrom(dialogRef.closed);
    }
}
