import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionInformationService {
    constructor() {}

    getItem(key: string, useLocalStorage: boolean = false) {
        let storage = sessionStorage;
        if (useLocalStorage) storage = localStorage;

        try {
            const storedValue = storage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : null;
        } catch (err) {}

        return null;
    }

    setItem(key: string, value: any, useLocalStorage: boolean = false) {
        let storage = sessionStorage;
        if (useLocalStorage) storage = localStorage;

        if (value) {
            if (value instanceof Array) {
                value = value.map(entity => {
                    delete entity.entityAspect;
                    return entity;
                });
            }
            storage.setItem(key, JSON.stringify(value));
        } else storage.removeItem(key);
    }

    removeItem(key: string, useLocalStorage: boolean = false) {
        const storage = useLocalStorage ? localStorage : sessionStorage;
        storage.removeItem(key);
    }

    retrieve(key: string, retrieveMethod: () => Promise<any>, useLocalStorage: boolean = false) {
        const value = this.getItem(key, useLocalStorage);
        if (value) {
            return value;
        } else {
            return retrieveMethod().then(data => {
                this.setItem(key, data, useLocalStorage);
                return data;
            });
        }
    }
}
